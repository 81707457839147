import React, { Component } from 'react';
import Loader from '../components/loader';
import Fade from 'react-reveal/Fade';

export default class Video extends Component {
  constructor(props) {
    super();
    this.state = {
      project: props.video,
      playing: props.currentSlide,
      loading: true,
      muted: true
    }
    this.videoRef = React.createRef()
    this.playVideo = this.playVideo.bind(this);
    this.toggleVideo = this.toggleVideo.bind(this);
    this.muteAll = this.muteAll.bind(this);

  }

  shouldComponentUpdate() {
    if (this.state.loading === false || this.props.page === "home" || this.props.page === "resident") {
      return true
    }
    else {
      return false
    }
  }

  componentDidUpdate (prevProps) {
    if(this.props.currentSlide !== prevProps.currentSlide) {
      this.setState({
        muted: true,
        playing: this.props.currentSlide
      })
      if ( this.videoRef.current != null ) {
        if ( this.props.currentSlide ) {
          this.videoRef.play()
        } else {
          this.videoRef.pause()
        }
      }
    }
  }


componentWillUnmount(){
  let elements = document.getElementsByClassName("video-meta");
  while(elements.length > 0){
      elements[0].parentNode.removeChild(elements[0]);
  }
}
 playVideo() {
   var video = this.refs.videoRef
   video.play();
 }
 toggleVideo () {
   const playing = this.state.playing
   this.setState({ playing: !playing });
   if (playing) {
     document.body.classList.add('video-view');
     this.setState({
       muted: false,
     });
   } else {
     document.body.classList.remove('video-view');
     this.setState({
       muted: true,
     });
   }
 }
 muteAll () {
   this.setState(prevState => ({
     muted: !prevState.muted
   }));
 }

  render() {
    const { project, index } = this.props
    if (!project || project.length < 2) {
      return <div className="no-video"><Loader /></div>
    }
    const { video, client, studio, agency, location } = project.acf

    let director = project && project.acf && project.acf.authorship && project.acf.authorship[0]? project.acf.authorship[0].post_title : null
    let title = project && project.title ? project.title.rendered : null
    let category = project && project._embedded && project._embedded['wp:term'] && project._embedded['wp:term']['0'] && project._embedded['wp:term']['0']['0'] ? project._embedded['wp:term']['0']['0'].name : null
    return (
      project &&
      <div className="video-wrapper">
        <Fade>
          <video ref={ thisVid => {this.videoRef = thisVid} }  className="project-video" loop playsInline muted={this.state.muted} autoPlay={index === 0} data-autoplay={index === 0} data-keepplaying onClick={this.toggleVideo}>
            <source src={video} type="video/mp4" />
          </video>
        </Fade>
        <div className={'project-' + index + '  video-meta video-meta-bottom' + (this.props.currentSlide ? ' active' : '')} ref={index}>
          <div className="content">
            <div className="row">
              <div className="col-6">
                <div className="video-meta-bottom-inner-wrapper">
                  <div className="video-info">
                    <h2 className="outline-white" onClick={this.toggleVideo}>{title}</h2>
                    <div className="more-info">
                      <h3>{director}</h3>
                      <span className="category">{category}</span>
                    </div>
                    <div className="progress-bar">
                      <div className="progress-bar-inner"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'video-meta video-meta-top content meta-' + index }>
          <div className="row">
            <div className="col-6 flex">
              <div className="meta-inner-wrapper">
                <div className="meta-single">
                  <h6>Director: <span>{director}</span></h6>
                </div>
                <div className="meta-single">
                  <h6>Production Studio: <span>{studio}</span></h6>
                </div>
                <div className="meta-single">
                  <h6>Client: <span>{client}</span></h6>
                </div>
                <div className="meta-single">
                  <h6>Agency: <span>{agency}</span></h6>
                </div>
                <div className="meta-single">
                  <h6>Location: <span>{location}</span></h6>
                </div>
              </div>
              <span className={'mute ' + this.state.muted} onClick={this.muteAll} >mute</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
