import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Container } from 'react-bootstrap'

import ScrollToTop from './components/scroll-to-top';
import Navigation from './components/navigation';
import Home from './pages/home'
import Residents from './pages/residents'
import Contact from './pages/contact'
import Resident from './pages/resident-single'
import Guest from './pages/guest-single'
import "./sass/style.scss";


const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/#reel', name: 'Reel', Component: Home },
  { path: '/contact', name: 'Contact', Component: Contact },
  { path: '/residents', name: 'Residents', Component: Residents },
  { path: '/directors/:slug', name: 'Guest', Component: Guest },
  { path: '/residents/:slug', name: 'Resident', Component: Resident },
]

function App() {

  return (
    <Router basename='/'>
      <>
      <Navigation />
        <ScrollToTop>
          <Container className="container" id="container">
            {routes.map(({ path, Component }) => (
              <Route key={path} path={path} exact>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={1200}
                    classNames="page"
                    unmountOnExit
                  >
                    <div className="page">
                      <Component />
                    </div>
                  </CSSTransition>
                )}
              </Route>
            ))}

          </Container>
        </ScrollToTop>
      </>

    </Router>
  )
}

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)
