import React, { Component } from 'react';
import Footer from '../components/footer';
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import Axios from "axios";
import Loader from '../components/loader';
import Fade from 'react-reveal/Fade';
import Image from 'react-shimmer'

export default class Residents extends Component {
  constructor() {
    super();
    this.state = {
      residents: [],
    }
  }
  componentDidMount() {
    Axios.get("http://admin.residencycontent.com/index.php/wp-json/wp/v2/residents?_embed&per_page=100").then(posts => {
      this.setState({
        residents: posts.data.filter(post => post.status === "publish"),
      });
    });
  }
  render() {

    if (this.state.residents.length === 0) {
      return <Loader />
    }

    return (
      <div className="page-wrapper residents" id="body">
      <Content residents={this.state.residents}/>
      <Footer />
      </div>
    );

  }
}



class Content extends Component {
  constructor() {
    super();
    this.state = {
      guests: [],
      residents: [],
      background: "",
      styles: [],
    }
  }
  componentDidMount() {
     Axios.get("http://admin.residencycontent.com/index.php/wp-json/wp/v2/directors?_embed&per_page=6").then(posts => {
     this.setState({
       guests: posts.data.filter(post => post.status === "publish"),
     });
   });
 }
 Editnavigate (e,id) {
     e.preventDefault();
     alert(id);
  }
  render() {
    let guests = this.state.guests.map((guest, index) => {
      let background_full= guest && guest._embedded && guest._embedded['wp:featuredmedia'] && guest._embedded['wp:featuredmedia']['0'] && guest._embedded['wp:featuredmedia']['0'].media_details && guest._embedded['wp:featuredmedia']['0'].media_details.sizes && guest._embedded['wp:featuredmedia']['0'].media_details.sizes.full ? guest._embedded['wp:featuredmedia']['0'].media_details.sizes.full.source_url : null
      let background_compressed = guest && guest._embedded && guest._embedded['wp:featuredmedia'] && guest._embedded['wp:featuredmedia']['0'] && guest._embedded['wp:featuredmedia']['0'].media_details && guest._embedded['wp:featuredmedia']['0'].media_details.sizes && guest._embedded['wp:featuredmedia']['0'].media_details.sizes.medium ? guest._embedded['wp:featuredmedia']['0'].media_details.sizes.medium.source_url : null
      let background_GIF = guest && guest.acf && guest.acf.background_gif ? guest.acf.background_gif.url : null
      let instagram = guest && guest.acf ? guest.acf.instagram_url : null
      return(
        <Fade>
          <div className="guest-outer-wrapper">
            <Link to={'/directors/' + guest.slug} key={'/directors/' + guest.slug}  exact>
              <div className="guest ratio-4-3">
                <Image
                  src={background_compressed ? background_compressed : background_full}
                  width={640} height={480}
                  style={{ objectFit: 'cover' }}
                  className="background-image"
                  duration={1200}
                />
                <Image
                  src={background_GIF}
                  width={640} height={480}
                  style={{ objectFit: 'cover' }}
                  className="background-gif"
                  duration={1200}
                />
              </div>
              <h5 className="name">{guest.title.rendered}</h5>
            </Link>
            {instagram &&
            <a href={instagram} target="_blank" rel="noopener noreferrer" className="instagram"><img src="./icons/instagram.svg" alt="instagram" /></a>
            }
          </div>
        </Fade>
      )

    });
    return (
    <Fade>
      <div className="page-body residents">

          <section className="section-residents">

            <div className="content">
              <div className="row heading">
                <div className="col-6">
                <h2>The Residents</h2>
                </div>
              </div>
            </div>

            <Resident residents={this.props.residents} />

          </section>

          <section className="section-guests">

            <div className="content">
              <div className="row heading">
                <div className="col-6">
                <h2>Guests</h2>
                </div>
              </div>

              <div className="row guests">
                <div className="col-6 flex">
                {guests}
                </div>
              </div>
            </div>

          </section>

        </div>
      </Fade>

    );
  }
}

class Resident extends React.Component {
  constructor() {
    super();
    this.state = {
      residents: [],
      offsetX: "",
      offsetY: "",
      do: ""
    }
  }
  async componentDidMount() {
    await this.props.residents
  }

  shouldComponentUpdate() {
    if (this.props.residents) {
      return false
    }
    else {
      return false
    }
  }

  render() {
     let residents = this.props.residents.map((resident, index) => {

       let background_full= resident && resident._embedded && resident._embedded['wp:featuredmedia'] && resident._embedded['wp:featuredmedia']['0'] && resident._embedded['wp:featuredmedia']['0'].media_details && resident._embedded['wp:featuredmedia']['0'].media_details.sizes && resident._embedded['wp:featuredmedia']['0'].media_details.sizes.full ? resident._embedded['wp:featuredmedia']['0'].media_details.sizes.full.source_url : null
       let background_compressed = resident && resident._embedded && resident._embedded['wp:featuredmedia'] && resident._embedded['wp:featuredmedia']['0'] && resident._embedded['wp:featuredmedia']['0'].media_details && resident._embedded['wp:featuredmedia']['0'].media_details.sizes && resident._embedded['wp:featuredmedia']['0'].media_details.sizes.medium ? resident._embedded['wp:featuredmedia']['0'].media_details.sizes.medium.source_url : null
       let delay = index * 50

       return(
         <Fade delay={delay}>
          <div className="resident-outer-wrapper">
             <Link to={'/residents/' + resident.slug} className="ratio-1-1">
               <div className="resident" >
                 <Image
                   src={background_compressed ? background_compressed : background_full}
                   width={640} height={480}
                   style={{ objectFit: 'cover', position:'absolute' }}
                   duration={1200}
                 />
                 <h5 className="name">{resident.title.rendered}</h5>
               </div>
             </Link>
           </div>
          </Fade>
       )
     });

     let residentNames = this.props.residents.map((resident, index) => {
       return(
             <Link to={'/residents/' + resident.slug} >
                 <h5 className="name">{resident.title.rendered}</h5>
             </Link>
       )
     });

    return (

      <div className="residents">

        <div className="content">
          <div className="row col-6">
            <div className="resident-names-wrapper">
              {residentNames}
            </div>
          </div>
      </div>

      <div className="content residents-outer-outer-wrapper">
        <div className="row residents-wrapper">
            {residents}
        </div>
      </div>
    </div>
    )
  }
}
