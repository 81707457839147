import React, { useState } from 'react';
import { Nav } from 'react-bootstrap'
import { BrowserRouter as Link, NavLink } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import { CSSTransition } from 'react-transition-group'


import Residents from '../pages/residents'
import Contact from '../pages/contact'
import Home from '../pages/home'

const routes = [
  { path: '/contact', name: 'Contact', Component: Contact },
  { path: '/residents', name: 'Residents', Component: Residents },
]


  export default function Navigation() {

    const [inProp, setInProp] = useState(false);

    // Set up Styles

    const openMenu = () => {
        document.body.classList.toggle('menu-open');
        if (!inProp) {
          setInProp(true);
        }
        else {
          setInProp(false);
        }
    }


      return (
        <div className="navigation residency-navigation" id="main-navigation">
          <div className="content">
            <div className="row">
              <div className="col-6">
              <div className="menu-outer-wrapper">

                  <div className="site-info">
                    <div className="logo-wrapper">
                          <div className="logo"><Nav.Link key="/" to="/" as={NavLink} exact><span className="r">R</span><span className="letters">esidency</span></Nav.Link></div>

                    </div>
                    <div id="menu-button" onClick={openMenu}>
                      <span className="top"></span>
                      <span className="middle"></span>
                      <span className="bottom"></span>
                    </div>
                  </div>


                  <CSSTransition
                    in={inProp}
                    timeout={300}
                    classNames="menu-items"
                    unmountOnExit
                  >
                  <div className="animated-menu menu content row">

                    <div className="close-menu" onClick={openMenu}></div>

                      <Fade top cascade duration={500} >

                          <div className="nav-links col-3 flex" >
                              {routes.map(route => (
                                <Nav.Link
                                  key={route.path}
                                  as={NavLink}
                                  to={route.path}
                                  onClick={openMenu}
                                  activeClassName="active"
                                  exact
                                >
                                  {route.name}
                                </Nav.Link>
                              ))}
                              <a href="http://www.paradoxal.net/" className="nav-link" target="_blank">Paradoxal</a>
                          </div>
                        </Fade>

                          <div className="company-information col-3">
                            <span className="address">
                              196 Hancock St. <br/>
                              Brooklyn, NY, 11216
                            </span>
                            <span className="address">
                              +1 (212)366-5526
                            </span>
                          </div>
                        </div>
                          </CSSTransition>

                      </div>



            </div>
          </div>
        </div>
      </div>

      );
  }
