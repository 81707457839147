import React, { Component } from 'react';
import Footer from '../components/footer';
import Axios from "axios";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Loader from '../components/loader';




export default class Contact extends Component {
  render() {
    return (
        <div className="page-wrapper contact" id="body">
          <Content />
          <Footer />
        </div>
    );
  }
}

class Content extends Component {
  constructor() {
    super();
    this.state = {
      contact: [],
      muted: 'muted',
      controlsHidden: 'controls-hidden',
      displayInfo: '',
      reel: []
    }
    this.openVideoView = this.openVideoView.bind(this);
    this.closeVideoView = this.closeVideoView.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }
  async componentDidMount() {
   await Axios.get("http://admin.residencycontent.com/index.php/wp-json/wp/v2/pages/22").then(post => {
     this.setState({
       contact: post.data
     });
   });

 }
 openVideoView () {

   const theVideo = document.getElementById('video');
   theVideo.scrollIntoView({behavior: "smooth", block: "start"});
   theVideo.currentTime = 0;
   theVideo.play();

   document.body.classList.add('video-view');

   this.setState({
     muted: false,
     displayInfo: "display"
   });
 }
 closeVideoView () {
   document.body.classList.remove('video-view');
   window.removeEventListener('scroll', this.handleScroll);
   this.setState({
     muted: true,
     displayInfo: "",
     controlsHidden: ""
   });
 }
 handleScroll(e) {
   document.body.classList.remove('video-view');
   window.removeEventListener('scroll', this.handleScroll);
   this.setState({
     muted: true,
     displayInfo: "",
     controlsHidden: ""
   });
}

  render() {
    let content = this.state.contact

    if (content.length === 0) {
      return <Loader />
    }

    let Header = content && content.acf ? content.acf.header : null
    let Subheading = content && content.acf ? content.acf.subheading : null
    let Messaging = content && content.acf ? content.acf.messaging : null
    let ContactEmail= content && content.acf ? content.acf.contact_email_address : null
    let FacebookUrl= content && content.acf ? content.acf.facebook_url : null
    let InstagramUrl= content && content.acf ? content.acf.instagram_url : null
    let reel = content && content.acf ? content.acf.contact_page_reel : null


    return (
      <Fade>
      <div className="page-body contact">

        <div className="content">

          <div className="row heading">
            <div className="col-3">
              <Fade>
                <h2>{Header}</h2>
              </Fade>
            </div>
            <div className="contact-button col-3">
              <Fade>
                <a className="button" href={'mailto:' + ContactEmail}>
                  <div className="button-inner-wrapper">
                    get in touch
                  </div>
                </a>
              </Fade>
            </div>
          </div>

          <div className="row tagline flex">
            <div className="col-4">
              <Fade>
                <h4>{Subheading}</h4>
              </Fade>
            </div>
          </div>

        </div>

        <div className="video">
          <video id="video" className="project-video" autoPlay playsInline loop onClick={this.closeVideoView} muted={this.state.muted}>
            <source src={reel} type="video/mp4" />
          </video>



          <div className="content">

            <div className="contact-links reel-fade">
              <a href={FacebookUrl} className="facebook" target="_blank" rel="noopener noreferrer"><div><img src="./icons/facebook.svg" alt="facebook" /></div></a>
              <a href={InstagramUrl} className="instagram" target="_blank" rel="noopener noreferrer"><div ><img src="./icons/instagram-white.svg" alt="instagram" /></div></a>
            </div>

            <div className="content-inner-wrapper">
              <div className="row reel-fade header col-6">
                <Fade bottom>
                  <h2 className="outline-white" onClick={this.openVideoView}>Play Reel</h2>
                </Fade>
              </div>
              <Fade>
                <div className="row reel-fade col-4 mission" dangerouslySetInnerHTML={{__html: Messaging}}></div>
              </Fade>
              <div class="content controls-wrapper reel-fade">
                <div className="row controls">
                  <Slide>
                    <div className="progress-bar">
                      <div className="progress-bar-inner"></div>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>

          </div>



        </div>

      </div>
      </Fade>
    );
  }
}
