
import React, { Component } from 'react';
import Footer from '../components/footer';
import ReactFullpage from '@fullpage/react-fullpage';
import Axios from "axios";
import { render } from 'react-dom';
import Video from '../components/video';
import Loader from '../components/loader';




export default class Home extends Component {
  componentDidMount() {
    document.body.classList.add('white-nav', 'white-nav-home');
  }
  componentWillUnmount() {
    document.body.classList.remove('white-nav', 'white-nav-home');
  }
  render() {
    return (
      <div className="page-wrapper home white-nav" id="body">
      <Projects />
      </div>
    );
  }
}

class Projects extends Component {
  constructor() {
    super();
    this.state = {
      projects: [],
      authorship: [],
      muted: true,
      reelClosed: "",
      loading: true,
      nextSlide: 0
    }
  }
  componentDidMount() {
    this.setState({ loading: true })
    this.loadFirstPage()
  }

  loadFirstPage() {
    Axios.get('http://admin.residencycontent.com/index.php/wp-json/wp/v2/projects?_embed=1&per_page=10&status=publish&meta_key=show_on_homepage&meta_value=true')
    .then(result => {
      this.setState({
        loading: false,
        projects: result.data
      }, () => {
        document.body.classList.add('loaded')
      })
    })
  }

  // !NOTE: Loads remaining pages after load. a little slow
  async loadPages () {
    await Axios.get('http://admin.residencycontent.com/index.php/wp-json/wp/v2/projects?_embed=1&offset=1&per_page=100&status=publish&meta_key=show_on_homepage&meta_value=true')
    .then(result => {
      console.log(result)
      const projects = this.state.projects.concat(result.data)
      this.setState({
        projects: projects
      })
    })
  }

  onLeave(origin, destination, direction) {
    this.setState({
      reelClosed: 'closed',
      muted: true,
      nextSlide: destination.index
    });
    document.body.classList.remove('video-view');
  }
  afterLoad(origin, destination, direction) {

  }
  render() {
    let projects = this.state.projects.slice(0, 6).map((project, index) => {
      return (
        <div className="project-wrapper section">
          <Video index={index} currentSlide={this.state.nextSlide === index} project={project} postType="projects" page="home" />
        </div>
      )
    });
    return (
      <div>
        <ReactFullpage
          //fullpage options
          licenseKey = {'YOUR_KEY_HERE'}
          scrollingSpeed = {750}
          fixedElements = {'.video-meta-bottom, .video-meta-top'}
          fadingEffect = {true}
          onLeave={this.onLeave.bind(this)}
          scrollHorizontally = {true}
          afterLoad={this.afterLoad.bind(this)}
          render={({ state, fullpageApi }) => {
            if (this.state.loading) {
              return (
                <ReactFullpage.Wrapper>
                  <div className="section">
                    <Loader />
                  </div>
                </ReactFullpage.Wrapper>
              )
            }
            return (
              <ReactFullpage.Wrapper>
                {projects}
                <Reel {...this.state} />
                <Footer />
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>
    );
  }
}

class Reel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offsetX: '',
      offsetY: '',
      friction: 1 / 32,
      options: [],
      viewReel: '',
      muted: true,
      loading: true
    }
    this._mouseMove = this._mouseMove.bind(this);
    this._handleResize = this._handleResize.bind(this);
    this.openReel = this.openReel.bind(this);
    this.closeReel = this.closeReel.bind(this);
  }
  componentDidMount() {
    Axios.get("http://admin.residencycontent.com/index.php/wp-json/acf/v3/options/options?_embed=1").then(result => {
      this.setState({
        options: result.data
      });
    });
    document.addEventListener('mousemove', this._mouseMove);
    window.addEventListener("resize", this._handleResize);
  }
  componentDidUpdate (prevProps) {
    if(this.props.nextSlide > prevProps.nextSlide) {
      this.setState({
        muted: true,
        viewReel: ''
      })
    }
    document.addEventListener('mousemove', this._mouseMove);
    window.addEventListener("resize", this._handleResize);
  }
  componentWillUnmount() {
    document.removeEventListener('mousemove', this._mouseMove);
    window.removeEventListener("resize", this._handleResize);
    // this.setState({
    //   viewReel: '',
    //   muted: true,
    // });
  }
  _handleResize(e) {
    if (window.innerWidth < 400) {
      this.setState({
        offsetX: 0,
        offsetY: 0
      });
    }
  };
  _mouseMove(e) {
    if (window.innerWidth > 400) {

    let followX = (window.innerWidth / 2 - e.clientX);
    let followY = (window.innerHeight / 2 - e.clientY);

    let x = 0,
        y = 0;
    x +=( (-followX - x) * this.state.friction);
    y += (followY - y) * this.state.friction;

    this.setState({
      offsetX: x,
      offsetY: y
    });
    }
    else {
      this.setState({
        offsetX: 0,
        offsetY: 0
      });
    }
  }
  openReel() {
    const theVideo = document.getElementById('the-reel');
    theVideo.currentTime = 0;
    theVideo.play();

    this.setState({
      viewReel: 'view-reel',
      muted: false,
      section_visibility: "hidden"
    });
    document.removeEventListener('mousemove', this._mouseMove);
  }
  closeReel() {
    this.setState({
      viewReel: '',
      muted: true,
    });
    document.addEventListener('mousemove', this._mouseMove);
  }
  render() {
    let offset = {
      transform: `perspective(600px)
                  rotateY(${this.state.offsetX}deg)
                  rotateX(${this.state.offsetY}deg)`
    }
    let homepageReel = this.state.options && this.state.options.acf ? this.state.options.acf.homepage_reel : null
    return (
      <div className={'project-wrapper section reel ' } id="reel">
        <div className={'content ' + this.state.viewReel}>

          <div className="row">
            <div className="col-6">
            <div className="rotate-wrapper" style={offset}>
            <video loop  autoPlay muted={this.state.muted} data-autoplay data-keepplaying className="reel-video project-video layer--1" onClick={this.closeReel} id="the-reel">
              <source src={homepageReel} type="video/mp4" />
            </video>
            <div className="brackets-wrapper layer-0">
              <div className="brackets top left"></div>
              <div className="brackets top right"></div>
              <div className="brackets bottom left"></div>
              <div className="brackets bottom right"></div>
            </div>
            <h2 className="outline-white layer-1" onClick={this.openReel}>Our Reel</h2>
            </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="video-meta-bottom-inner-wrapper">
                <div className="video-info">
                  <div className="progress-bar"></div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    )
  }
}
