import React, { Component } from 'react';
//import { browserHistory } from 'react-router';
import Footer from '../components/footer';
import ReactFullpage from '@fullpage/react-fullpage';
import Axios from "axios";
import {useParams} from "react-router-dom";
import Video from '../components/video';
import Fade from 'react-reveal/Fade';
import Image from 'react-shimmer'
import Loader from '../components/loader';

function Guest() {

    let {slug} = useParams();
    return (
          <div className="page-wrapper director-single guest-single white-nav" id="body">
          <Projects slug={slug} />
          </div>
        );
}


class Projects extends Component {

  constructor() {
    super();
    this.state = {
      projects: [],
      authorship: [],
      footer: "footer-black",
      slug: "",
      muted: true,
      nextSlide: 0
    }
  }
  async componentDidMount() {
    const slug = await this.props.slug
    Axios.get("http://admin.residencycontent.com/index.php/wp-json/wp/v2/directors?slug=" + slug)
    .then(posts => {
      let includes = ''
      posts.data[0].acf.authorship.forEach( project => {
        includes += `&include[]=${ project.ID }`
      })
      Axios.get('http://admin.residencycontent.com/index.php/wp-json/wp/v2/projects?_embed=1' + includes)
      .then(result => {
        this.setState({
          loading: false,
          projects: result.data
        })
      })
    });
    document.body.classList.add('white-nav');
    setTimeout(
       function() {
           this.setState({footer: "footer-white"});
       }
       .bind(this),
       3000
   );
 }
  onLeave(origin, destination, direction) {
    document.body.classList.remove('video-view');
    this.setState({
      muted: true,
      nextSlide: destination.index
    });
  }
 componentWillUnmount() {
   document.body.classList.remove('white-nav');
 }
  render() {
    let projects = this.state.projects.map((project, index) => {
      let video = project && project.acf && project.acf.authorship &&  project.acf.authorship[0] ? project.acf.authorship[0].post_name : null
      return(
        <div className="project-wrapper section">
          <Video currentSlide={this.state.nextSlide === index} project={project} index={index} postType="projects" {...this.state} page="guest"/>
        </div>
      )
    });
    return (
      <div>
        {
          projects.length === 0
          ? <Loader />
          : <ReactFullpage
              //fullpage options
              licenseKey = {'YOUR_KEY_HERE'}
              scrollingSpeed = {750}
              fixedElements = {'.video-meta'}
              fadingEffect = {true}
              onLeave={this.onLeave.bind(this)}
              render={({ state, fullpageApi }) => {

                return (
                  <ReactFullpage.Wrapper>
                    {projects}
                    <div className={"director-info section fp-auto-height " + this.state.footer}>
                      <Director slug={this.props.slug} />
                      <Footer />
                    </div>
                  </ReactFullpage.Wrapper>
                );
              }}
            />
          }
      </div>
    );
  }
}


class Director extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
    };
    this.createMarkup = this.createMarkup.bind();
  }

  componentDidMount() {
    const slug = this.props.slug;

    Axios
      .get(`http://admin.residencycontent.com/index.php/wp-json/wp/v2/directors?_embed&slug=` + slug)
      .then(post => {
        this.setState({
          content: post.data[0]
        });
      });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    let director = this.state.content

    let title = director && director.title ? director.title.rendered : null
    let website_url = director && director.acf ? director.acf.website_url : null
    let instagram_url = director && director.acf ? director.acf.instagram_url : null

    let background_full = director && director._embedded && director._embedded['wp:featuredmedia'] && director._embedded['wp:featuredmedia']['0'] && director._embedded['wp:featuredmedia']['0'].media_details && director._embedded['wp:featuredmedia']['0'].media_details.sizes && director._embedded['wp:featuredmedia']['0'].media_details.sizes.full ? director._embedded['wp:featuredmedia']['0'].media_details.sizes.full.source_url : null
    let background_compressed = director && director._embedded && director._embedded['wp:featuredmedia'] && director._embedded['wp:featuredmedia']['0'] && director._embedded['wp:featuredmedia']['0'].media_details && director._embedded['wp:featuredmedia']['0'].media_details.sizes && director._embedded['wp:featuredmedia']['0'].media_details.sizes.medium ? director._embedded['wp:featuredmedia']['0'].media_details.sizes.medium.source_url : null


    return (
      <div className="content">
        <div className="row">
          <div className="col-3 director-wrapper">

              {background_full || background_compressed ? (
              <Fade>
                <div className="portrait-wrapper">
                <Image
                  src={background_compressed ? background_compressed : background_full}
                  width={640} height={480}
                  style={{ objectFit: 'cover', position:'absolute' }}
                  className="director-portrait"
                  duration={1200}
                />
              </div>
            </Fade>
          ) : ""}

            <div className="director-name" >
              <h3>{title}</h3>
              <p>Director</p>
            </div>
          </div>
          <div className="col-3 flex buttons">
            {website_url &&
              <a className="button" href={website_url} target="_blank" rel="noopener noreferrer">
                <div className="button-inner-wrapper">
                  website
                </div>
              </a>
            }
            {instagram_url &&
              <a className="button" href={instagram_url} target="_blank" rel="noopener noreferrer">
                <div className="button-inner-wrapper">
                  instagram
                </div>
              </a>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Guest;
