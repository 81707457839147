import React, { Component } from 'react';
import Axios from "axios";
import { Nav } from 'react-bootstrap'
import { BrowserRouter as Link, NavLink } from 'react-router-dom'
import Fade from 'react-reveal/Fade';


import Home from '../pages/home'



export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    Axios.get('http://admin.residencycontent.com/index.php/wp-json/acf/v3/options/options?_acf=1').then(res => {
      this.setState({options: res.data});
    });
  }

  render() {
  let options= this.state.options;

  if (options.length === 0) {
    return <div />
  }

  let address = options && options.acf ? options.acf.address : null
  let phone = options && options.acf ? options.acf.phone_number : null
  return (
    <div className="footer section fp-auto-height">
      <div className="content">
        <div className="row">

          <div className="col-1 company-information">
            <span className="address" dangerouslySetInnerHTML={{__html: address}}>

            </span>
            <span className="address">
              {phone}
            </span>
          </div>

          <Agents />

        </div>
      </div>

      <div className="logos">
        <Nav.Link
          key="/"
          as={NavLink}
          to="/"
          activeClassName="active"
          exact
        >
        <div className="residency"><img src={"http://residencycontent.com/logos/r-logo.png"} alt="Residency" /></div>
        </Nav.Link>
        <a href="http://www.paradoxal.net/" target="_blank" rel="noopener noreferrer">
          <div className="paradoxal"><img src={"http://residencycontent.com/logos/p-logo.png"} alt="Paradoxal" /></div>
        </a>
      </div>

    </div>
    )
  }
}

class Agents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      agents: [],
    };
  }

  async componentDidMount() {
    await Axios.get('http://admin.residencycontent.com/index.php/wp-json/acf/v3/options/options').then(res => {
      this.setState({agents: res.data});
    });
  }

  render() {

  if (!this.state.agents.acf) {
    return null;
  }

  let content = this.state.agents && this.state.agents.acf ? this.state.agents.acf.staff : null
  let agents = content.map((agent, index) => {

  let delay = index * 50

  return (
          <Fade delay={delay} key={index} >
            <div className="agent">
              <h6>{agent.name}</h6>
              <span>{agent.title}</span>
              <a href={'mailto:' + agent.email}>{agent.email}</a>
            </div>
          </Fade>
        )
    });
    return  (

        <div className="col-5 agents" >
          {agents}
        </div>

    )
  }
}
